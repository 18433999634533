'use strict';

var AutocompleteModel = Backbone.Model.extend();
var AutocompleteCollection = Backbone.Collection.extend({
  initialize: function (options) {
    this.options = options || {};
  },

  _autocompletePool: [], // definisco un array che conterrà tutte le richieste
  parse: function (response) {
    switch (response.code) {
      case 404:
        return [];
      default:
        return response.data;
    }
  },

  sync: function (method, model, options) {
    options = options || {};
    // impostazioni per comunicare con il backend
    if (!options.crossDomain) {
      options.crossDomain = true;
    }

    if (!options.xhrFields) {
      options.xhrFields = { withCredentials: true };
    }

    // ciclo tutte le richieste salvate nell'array _autocompletePool
    _.each(this._autocompletePool, function (request) {
      // se le richieste sono ancora in pending
      if (request.readyState !== 4) {
        // cancello la richiesta
        request.abort();
      }
    });

    // controllo che sia effettuata una richiesta di lettura
    if (method === 'read') {
      var xhr = proxiedSync(method, model, options);
      // inserisco la richiesta nell'array
      this._autocompletePool.push(xhr);
      return xhr;
    }
    return proxiedSync(method, model, options);
  },

  url: function () {
    return APIURL + this.options.url;
  },

  model: AutocompleteModel,
});

// itemView utilizzato per mostra il feedback di loading all'utente
var AutocompleteLoadingItemView = Marionette.ItemView.extend({
  template: '#autocompleteLoadingTemplate',
  className: 'recordList__record__hints__message',
});

/**
 * [ recupera i dati dal database, gestisce le risposte che vengono ritornate
 * dal server ]
 * @param  {[Object]} options [ contiene le opzioni con cui si deve costruire
 * l'URL della collection ]
 * @return {[JQuery]}         [ viene ritornata una promise ]
 */
var getAutocomplete = function (options) {
  var defer = $.Deferred();
  var collection = new AutocompleteCollection(options);

  collection.fetch({
    success: function (coll, response) {
      if (response.code === 200 || response.code === 404) {
        defer.resolve(coll);
      } else {
        defer.resolve(undefined);
      }
    },

    error: function (data, response) {
      if (response.statusText !== 'abort') {
        defer.resolve(undefined);
      } else {
        defer.resolve('request-aborted');
      }
    },
  });

  return defer.promise();
};

/**
 * [ evento utilizzato per l'autocompletamento, si occupa del recupero dei dati
 * dal server e li visualizza all'utente tramite la collectionView che gli viene
 * ritornata ]
 *
 * @param  {[Object]} options [ contiene le opzioni da passare alla collection
 * autocomplete ]
 * @param  {[Marionette obj]} collectionView [ istanza della collectionView che
 * verrà utilizzata per mostrare i risultati all'utente ]
 * @param  {[jQUery obj]} region [ obj jQuery del DOM all'interno del quale
 * verrà renderizzata la collectionView ]
 *
 * @param  {[Marionette obj]} parent [ view Marionette all'interno del quale si
 * sta renderizzando la CollectionView ]
 * @param  {[String]} label [ stringa utilizzata per distinguere gli eventi
 * ritornati al parent ]
 */
dispatcher.on('get:autocomplete', function (options, CollectionView, region, parent, label) {
  var loadingTemplate = new AutocompleteLoadingItemView();
  region.html(loadingTemplate.render().$el);

  var autocompleteRequest = getAutocomplete(options);
  $.when(autocompleteRequest).done(function (autocompleteResponse) {
    if (!_.isUndefined(autocompleteResponse)) {
      var autocompleteTemplate;

      // richiesta cancellata
      if (autocompleteResponse === 'request-aborted') {
        // render della view di loading
        loadingTemplate = new AutocompleteLoadingItemView();
        region.html(loadingTemplate.render().$el);
      } else {
        autocompleteTemplate = new CollectionView({
          collection: autocompleteResponse,
          childViewOptions: {
            parent: parent,
          },
        });
        region.html(autocompleteTemplate.render().$el);

        // se la collection ritornata dal server è vuota
        if (_.isEmpty(autocompleteResponse.models) && !_.isUndefined(parent)) {
          // comunico che non è stato trovato nessun risultato
          parent.trigger('autocomplte:noResult', region, label);
        }
      }
    } else {
      // TODO gestire errori tornati dal server
      // not found
      loadingTemplate = new AutocompleteLoadingItemView();
      region.html(loadingTemplate.render().$el);
    }
  });
}, $, getAutocomplete, AutocompleteLoadingItemView);
