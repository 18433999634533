'use strict';

// render small spinning loading on ui (only if it is not already rendered)
dispatcher.on('show:page:loading', function (renderZone) {
  if ($(renderZone).html() !== '') {
    return;
  }

  var loadingView = new Wethod.View.Loading.SmallView();
  $(renderZone).html(loadingView.render().$el);
});

// hide small spinning loading on ui
dispatcher.on('remove:page:loading', function (renderZone) {
  $(renderZone).html('');
});
